import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import taManager from '@/utils/thinkingdataManager'
import adsManager from '@/utils/adsManager'
import gaManager from '@/utils/gaManager'

import './assets/styles/reset.css'

taManager.init()
adsManager.removeGoggleLocalStorage()
adsManager.init()
gaManager.init()

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')
