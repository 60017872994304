import { defineStore } from 'pinia'

const ua = navigator.userAgent,
  isWindowsPhone = /(?:Windows Phone)/.test(ua),
  isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
  isAndroid = /(?:Android)/.test(ua),
  isFireFox = /(?:Firefox)/.test(ua),
  // isChrome = /(?:Chrome|CriOS)/.test(ua),
  isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua)),
  isPhone = /(?:iPhone)/.test(ua) && !isTablet,
  isPc = !isPhone && !isAndroid && !isSymbian
const result = {
  isTablet: isTablet,
  isPhone: isPhone,
  isAndroid: isAndroid,
  isPc: isPc,
}

export const usePlatformStore = defineStore({
  id: 'platform',
  state: () => ({
    // 标识是否为移动端设备
    // isMobile: !!navigator.userAgent.match(
    //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    // ),
    isMobile: !result.isPc,
    os: result,
  }),
})
