import router from '@/router'
import { pubIdObj } from '@/consts'

const product = import.meta.env.VITE_API_PRODUCT
const pubId = pubIdObj[product]

// 获取广告html片段
// 分固定大小广告和自适应大小广告
const afcConfig = {
  // 固定样式广告位配置
  getFixedStyleAdHtml: (slotId, styleText) =>
    `<ins class="adsbygoogle"
      style="display:inline-block;${styleText}"
      data-ad-client="${pubId}"
      data-ad-slot="${slotId}"></ins>`,
  // 自适应大小广告位配置
  getAutoStyleAdHtml: (
    slotId,
    styleText = 'display:flex;justify-content:center;align-items:center;'
  ) =>
    `<ins class="adsbygoogle"
      style="${styleText}"
      data-ad-client="${pubId}"
      data-ad-slot="${slotId}"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>`,
}

// 获取今天的日期字符串
function getDateStr() {
  const d = new Date()
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}

class AdsManager {
  // 插入广告sdk
  init() {
    // 异步 加载 adsense 广告
    let script = document.createElement('script')
    script.setAttribute('async', '')
    script.setAttribute('crossorigin', 'anonymous')
    script.setAttribute('data-ad-frequency-hint', '30s')
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${pubId}`
    document.body.appendChild(script)
    // afg
    window.adsbygoogle = window.adsbygoogle || []
    window.adBreak = window.adConfig = function (o) {
      window.adsbygoogle.push(o)
    }

    window.adConfig({
      preloadAdBreaks: 'on',
      sound: 'off',
    })
  }

  // 今天是否已显示过afx广告
  isAFXShowed() {
    return getDateStr() === localStorage.getItem('adShowDate')
  }

  // 生成afc广告
  generateAFCAd(adStr, parentDom) {
    parentDom.innerHTML = adStr
    const script = document.createElement('script')
    script.innerHTML = '(adsbygoogle = window.adsbygoogle || []).push({});'
    parentDom.appendChild(script)
  }

  // 生成afg广告
  generateAFGAd(type, options = {}) {
    const config = {
      type: type || 'next', // start, next, reward
      name: 'game_x_ads',
      beforeAd: () => {
        // console.log('beforeAd')
      },
      afterAd: () => {
        // console.log('afterAd')
      },
      adBreakDone: (/* placementInfo */) => {
        // console.log('adBreakDone: ', placementInfo)
      },
    }

    if (type === 'reward') {
      Object.assign(config, {
        beforeReward: (/* showAdFn */) => {
          // console.log('beforeReward')
          // showAdFn()
        },
        adDismissed: () => {
          // console.log('adDismissed')
        },
        adViewed: () => {
          // console.log('adViewed')
        },
      })
    }

    Object.assign(config, options)

    window.adBreak(config)
  }

  // 页面加载完后，插入AFC广告
  insertAFC(adsDom, isFixedStyle, slotId, styleText, callback = () => {}) {
    const getAfcConfigFunc = isFixedStyle
      ? afcConfig.getFixedStyleAdHtml
      : afcConfig.getAutoStyleAdHtml
    const afcHtmlStr = getAfcConfigFunc(slotId, styleText)

    if (afcHtmlStr && adsDom) {
      this.generateAFCAd(afcHtmlStr, adsDom)

      callback()
    }
  }

  // 页面加载完后，弹出全屏广告，每天只弹一次
  insertAFX(callback = () => {}) {
    if (router.currentRoute.value.query.ax === '1' && !this.isAFXShowed()) {
      const type = 'start'
      this.generateAFGAd(type, {
        adBreakDone: (placementInfo) => {
          window.console.log('显示穿插屏')
          window.console.log(placementInfo)

          if (['viewed', 'dismissed'].includes(placementInfo.breakStatus)) {
            localStorage.setItem('adShowDate', getDateStr())
          }
          callback()
        },
      })
    } else {
      callback()
    }
  }

  // 贴片广告
  insertAFX_PC(callBack = () => {}, name = 'game_preroll') {
    let self = this
    let e
    if (!(e >= 2))
      return (
        (e = 0),
        () => {
          if (e < 2) {
            // eslint-disable-next-line
            console.log("贴片广告", window.adBreak)
            try {
              window.adBreak({
                type: 'preroll',
                name,
                adBreakDone(t) {
                  // eslint-disable-next-line
                  console.log("贴片广告播放结束 --- ", t.breakStatus),
                    t.breakStatus === 'notReady' &&
                      setTimeout(() => {
                        e++
                        self.insertAFX_PC(callBack, name)()
                      }, 1e3)
                  callBack()
                },
              })
            } catch (t) {
              // eslint-disable-next-line
              console.error("贴片广告 error: ", t)
              callBack()
            }
          }
        }
      )
  }

  removeGoggleLocalStorage() {
    const removeKeys = ['__lsv__', 'google_adsense_settings']
    for (const key of removeKeys) {
      localStorage.removeItem(key)
    }
  }
}

export default new AdsManager()
