<template>
  <MobileLayout v-if="isMobile"></MobileLayout>
  <DesktopLayout v-else></DesktopLayout>
</template>

<script setup>
import { ref, defineAsyncComponent, onMounted, onUnmounted } from 'vue'
import { usePlatformStore } from '@/stores/platform'
import gaManager from '@/utils/gaManager'
import { EVENT_TYPE } from '@/consts'

const platformStore = usePlatformStore()
const { isMobile } = platformStore
let blurToIframeFlag = ref(false)

const visibilitychangeFn = () => {
  // 切换 新的标签页
  if (document.visibilityState === 'hidden') {
    if (blurToIframeFlag.value) {
      // console.log('从iframe离开了当前页')
      blurToIframeFlag.value = false
      // alert('广告点击')
      gaManager.track(EVENT_TYPE.ALL.ADS_CLICK_MODE2, {
        event_category: 'ads_click',
      })
    }
  }
  // 回到 当前标签页
  if (document.visibilityState === 'visible') {
    // console.log('回到了当前页')
    // document.querySelector('.header-logo').focus()
    window.focus()
  }
}

const blurFn = () => {
  // console.log('window blur')
  setTimeout(() => {
    if (document.activeElement.tagName === 'IFRAME') {
      blurToIframeFlag.value = true
      // console.log('window blur true')
    } else {
      blurToIframeFlag.value = false
      // console.log('window blur false')
    }
  })
}

const tiktokBlurFn = () => {
  // 只监听 页面 固定的 广告位 的点击情况
  const iframeList = document.querySelectorAll('.advertisement iframe')
  for (let i = 0; i < iframeList.length; i++) {
    const iframe = iframeList[i]
    if (iframe === document.activeElement) {
      // eslint-disable-next-line
      console.log('ads click')
      gaManager.track(EVENT_TYPE.ALL.ADS_CLICK, { event_category: 'ads_click' })
      window?.ttq?.track('AddToCart')
      setTimeout(() => {
        window.focus()
      })
    }
  }
}

onMounted(() => {
  // console.log('添加 监听')
  // 监听点击广告
  document.addEventListener('visibilitychange', visibilitychangeFn)
  window.addEventListener('blur', blurFn)
  window.addEventListener('blur', tiktokBlurFn)
})

onUnmounted(() => {
  // 移除 监听点击广告 防止下次 重复添加监听事件
  document.removeEventListener('visibilitychange', visibilitychangeFn)
  window.removeEventListener('blur', blurFn)
  window.removeEventListener('blur', tiktokBlurFn)
})

// 区分桌面与移动，加载不同的组件
// 页面也用同样的方法加载不同组件
const DesktopLayout = defineAsyncComponent(() => import('@/layout/desktop.vue'))
const MobileLayout = defineAsyncComponent(() => import('@/layout/mobile.vue'))
</script>
