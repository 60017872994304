import thinkingdata from 'thinkingdata-browser'

const VITE_API_DOT_DEBUG = import.meta.env.VITE_API_DOT_DEBUG
const VITE_API_DOT_SHOWLOG = import.meta.env.VITE_API_DOT_SHOWLOG

// 数数 打点
class thinkingdataManager {
  ta = thinkingdata
  init() {
    const config = {
      appId: 'ad70b98c36734d3b8ae3659f85760168',
      serverUrl: 'https://ta-receiver-sgp.g.mi.com/sync_js',
      // send_method: 'ajax',
      showLog: Boolean(VITE_API_DOT_SHOWLOG),
    }
    if (VITE_API_DOT_DEBUG) {
      config['mode'] = VITE_API_DOT_DEBUG
    }
    this.ta.init(config)
  }

  track(eventName, options = {}) {
    this.ta.track(eventName, options)
  }
}

export default new thinkingdataManager()
