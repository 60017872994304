import { createRouter, createWebHistory } from 'vue-router'

const Home = () =>
  import(/* webpackChunkName: "a4dfabdacb27db77" */ '../views/home/index.vue')
const Category = () =>
  import(
    /* webpackChunkName: "b05bf00afc29fde2" */ '../views/category/index.vue'
  )
const Detail = () =>
  import(/* webpackChunkName: "9e7e55f494a4557a" */ '../views/detail/index.vue')
const Played = () =>
  import(
    /* webpackChunkName: "9681b7add61f6bc2" */ '../views/played/desktop.vue'
  )
const Favorite = () =>
  import(
    /* webpackChunkName: "af7dc093e38da388" */ '../views/favorite/desktop.vue'
  )
const Search = () =>
  import(/* webpackChunkName: "8054e13c42ba1ca5" */ '../views/search/index.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/category/:category',
      name: 'category',
      component: Category,
    },
    {
      path: '/detail',
      name: 'detail',
      component: Detail,
    },
    {
      path: '/recent',
      name: 'recent',
      component: Played,
    },
    {
      path: '/favorite',
      name: 'favorite',
      component: Favorite,
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
    },
    // {
    //   path: '/:pathMatch(.*)*',
    //   name: 'NotFound',
    //   component: Home,
    // },
  ],
})

export default router
